export async function getNearbySearch(google, map, location, type) {
  return new Promise((resolve) => {
    let places = [];
    const pyrmont = new google.maps.LatLng(location.lat, location.lng);
    const service = new google.maps.places.PlacesService(map);
    const request = {
      location: pyrmont,
      type: type,
      rankBy: google.maps.places.RankBy.DISTANCE,
    };

    service.nearbySearch(request, (results, status, pagination) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const res = results.map((result) => {
          return {
            name: result.name,
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
            location: result.geometry.location
          }
        });
        places = places.concat(res);
        if (pagination.hasNextPage) {
          pagination.nextPage();
        } else {
          resolve(places);
        }
      } else {
        resolve(places);
      }
    });
  });
};