import React from 'react';
import ReactDOM from 'react-dom';

const appBarMarginTop = 60;
const mapStyles = {
  map: {
    position: 'absolute',
    width: '100%',
    marginTop: appBarMarginTop,
    height: `calc(100% - ${appBarMarginTop}px)`,
  },
};

export class CurrentLocation extends React.Component {
  constructor(props) {
    super(props);

    const { lat, lng } = this.props.initialCenter;
    this.state = {
      currentLocation: {
        lat: lat,
        lng: lng,
      },
    };
  }

  handleEvent(evtName) {
    let timeout;
    const handlerName = evtName;

    return (e) => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = setTimeout(() => {
        if (this.props[handlerName]) {
          this.props[handlerName](this.props, this.map, e);
        }
      }, 0);
    };
  }

  loadMap() {
    if (this.props && this.props.google) {
      // checks if google is available
      const { google } = this.props;
      const maps = google.maps;

      const mapRef = this.refs.map;

      // reference to the actual DOM element
      const node = ReactDOM.findDOMNode(mapRef);

      let { zoom } = this.props;
      const { lat, lng } = this.state.currentLocation;
      const center = new maps.LatLng(lat, lng);
      const mapConfig = Object.assign(
        {},
        {
          center: center,
          zoom: zoom,
        }
      );

      // maps.Map() is constructor that instantiates the map
      this.map = new maps.Map(node, mapConfig);
      this.map.setTilt(0);
      const evtNames = ['ready', 'click', 'dragend'];
      evtNames.forEach((e) => {
        this.map.addListener(e, this.handleEvent(e));
      });
      maps.event.trigger(this.map, 'ready');
      maps.event.trigger(this.map, 'click');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log("map is changed ", this.props.currentLocation)
    if (
      this.props.currentLocation.lat !== '' &&
      this.props.currentLocation !== this.state.currentLocation
    ) {
      this.setState({
        currentLocation: this.props.currentLocation,
      });
    }
    if (prevProps.google !== this.props.google) {
      this.loadMap();
    }
    if (prevState.currentLocation !== this.state.currentLocation) {
      this.recenterMap();
    }
  }

  recenterMap() {
    const map = this.map;
    const current = this.state.currentLocation;

    const google = this.props.google;
    const maps = google.maps;

    if (map) {
      let center = new maps.LatLng(current.lat, current.lng);
      map.panTo(center);
    }
  }

  componentDidMount() {
    if (this.props.centerAroundCurrentLocation) {
      if (navigator && navigator.geolocation !== undefined){
        var options = {
          // enableHighAccuracy: true,
          timeout: 8000,
          maximumAge: 0
        };
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            const coords = pos.coords;
            this.setState({
              currentLocation: {
                lat: coords.latitude,
                lng: coords.longitude,
              },
            });
            this.loadMap();
          },
          (err) => {
            console.log('現在地の取得に失敗しました。原因：', err);
            this.loadMap();
          }
          ,options
        );
      }
    } else {
      this.loadMap();
    }
  }

  renderChildren() {
    const { children } = this.props;

    if (!children) return;

    return React.Children.map(children, (c) => {
      if (!c) return;
      return React.cloneElement(c, {
        map: this.map,
        google: this.props.google,
        mapcenter: this.state.currentLocation,
      });
    });
  }
  render() {
    const style = Object.assign({}, mapStyles.map);
    return (
      <div>
        <div style={style} ref='map'>
          Loading map...
        </div>
        {this.renderChildren()}
      </div>
    );
  }
}
export default CurrentLocation;

CurrentLocation.defaultProps = {
  zoom: 14,
  initialCenter: {
    lat: 35.686086,
    lng: 139.752161,
  },
  centerAroundCurrentLocation: false,
  visible: true,
};
