import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dialogContent: {
    paddingBottom: '0',
  },
  dialogContentText: {
    marginBottom: '0',
    color: 'black',
  },
  textLabel: {
    fontWeight: 'normal',
  }
}));

export default function AlertDialog(props) {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
          {props.msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleClose}
          autoFocus
          component="label"
          variant="contained"
          classes={{label: classes.textLabel}}
        >
          {props.okBtnName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
