import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
import AlertDialog from './Alert';
import {
    isBrowser,
} from "react-device-detect";
export class PhotoUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            imgFiles: props.savedPhotos,
            showingAlDialog: 4 - props.savedPhotoNum <= 0,
            canUploadPhotoNum: 4 - props.savedPhotoNum
        };
        this.inputRef = React.createRef();
    }

    handleCapture = (event) => {
        let photos = event.target.files;
        if (photos.length + this.state.imgFiles.length > 4) {
            this.setState({
                showingAlDialog: true
            });
        } else {
            this.props.enableUpload();
            this.setState({
                files: photos
            });
            const files = [].map.call(photos, function (f) {
                return { img: URL.createObjectURL(f), title: f.name, cols: 0 };
            });
            this.setState({
                imgFiles: files.slice(0, 4)
            });
        }
    };

    render() {
        var imgFiles = this.state.imgFiles
        return (
            <div>
                <List>
                    <ListItem
                        button
                        disabled={this.state.canUploadPhotoNum <= 0}
                        key='写真アップロード'
                        component='label'>
                        <ListItemIcon>
                            <InsertPhotoIcon />
                        </ListItemIcon>
                        <ListItemText primary='写真アップロード' />
                        <input
                            accept='image/*'
                            type='file'
                            multiple max="4"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                                this.handleCapture(e);
                            }}
                            ref={this.inputRef}
                        />
                    </ListItem>
                </List>
                <GridList
                    cellWidth={isBrowser ? 160 : 80}
                    cellHeight={isBrowser ? 160 : 80}
                    cols={imgFiles.length > 2 ? 2 : imgFiles.length}
                >
                    {imgFiles.map((tile) => (
                        <img src={tile.img} alt={tile.title} />
                    ))}
                </GridList>
                <AlertDialog
                    open={this.state.showingAlDialog}
                    title={"写真数の確認"}
                    msg={ this.state.canUploadPhotoNum > 0 ? "写真数は４枚までなので、あと" + this.state.canUploadPhotoNum + "枚しかアップロードできません。"
                : "写真数は４枚以上になったため、アップロードできません。"}
                    okBtnName={"了解"}
                    handleClose={e => {
                        this.setState({
                            showingAlDialog: false,
                        })
                    }}
                    handleOK={e => {
                        this.setState({
                            showingAlDialog: false,
                        })
                    }}
                />
            </div>
        );
    }
}
export default PhotoUploader;