export default function getNearestStation(google, map, pos, callback, errorhandler) {
    var request_subway = {
        location: pos,
        rankBy: google.maps.places.RankBy.DISTANCE,
        type: 'subway_station',
        language: 'ja'
    };
    var request_train = {
        location: pos,
        rankBy: google.maps.places.RankBy.DISTANCE,
        type: 'train_station',
        language: 'ja'
    };
    var service = new google.maps.places.PlacesService(map);

    service.nearbySearch(request_subway, (results_subway, status, pagination) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
            service.nearbySearch(request_train, (results_train, status, pagination) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    let dis= [];
                    for (let i = 0; i < 3 && i < results_subway.length; i++) {
                        let station = {}
                        station['distance'] = google.maps.geometry.spherical.computeDistanceBetween(
                            pos,
                            results_subway[i].geometry.location
                        );
                        station['result'] = results_subway[i];
                        dis.push(station)
                    }
                    for (let i = 0; i < 3 && i < results_train.length; i++) {
                        let station = {}
                        station['distance'] = google.maps.geometry.spherical.computeDistanceBetween(
                            pos,
                            results_train[i].geometry.location
                        );
                        station['result'] = results_train[i];
                        dis.push(station)
                    }
                    // 距離をキーとして重複を排除
                    dis = Array.from(new Map(dis.map(o => [o.distance, o])).values())
                    dis.sort((a, b) => {
                        if(a.distance > b.distance) {
                            return 1;
                        } else if(a.distance < b.distance) {
                            return -1;
                        }
                        return 0;
                    });
                    
                    let results = [];
                    for (let item of dis) {
                        results.push(item['result']);
                    }

                    if (typeof callback == "function")
                        callback(results);
                }
                else {
                    if (typeof callback == "function")
                        callback(results_subway);
                }
            });
        }
        else {
            service.nearbySearch(request_train, (results_train, status, pagination) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    if (typeof callback == "function")
                        callback(results_train);
                }
                else {
                    if (typeof errorhandler == "function")
                        errorhandler(status);
                }
            });
        }
    });
}