export function between(a, b) {
    const R = Math.PI / 180;

    const lat1 = R * a.lat;
    const lng1 = R * a.lng;
    const lat2 = R * b.lat;
    const lng2 = R * b.lng;

    let cos = Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2);
    cos = clip(cos, -1, 1);
    const km = 6371 * Math.acos(cos);
    return round(km * 1000, 3);
}

const round = (num, digit) => {
    if (typeof num === 'undefined') {
      return undefined;
    }
    const digitVal = Math.pow(10, digit);
    return Math.round(num * digitVal) / digitVal;
}

function clip(val, min, max){
    if (val < min)
        return min;
    else if (max < val)
        return max;
    return val;
}
