import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inputGroupTitle: {
    textAlign: 'center',
    height: '40px',
    padding: '0px',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '20pt',
    textDecoration: 'underline',
    background: 'lightgray',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    border: 'solid 1.5px black',
  },
  h1: {
    textAlign: 'center',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '36pt',
    textDecoration: 'underline',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  table: {
    borderCollapse: 'collapse',
    border: 'solid 1.5px black',
    margin: 'auto',
    width: '80%',
  },
  result: {
    fontSize: '25pt',
    padding: '3px',
    color: 'black',
    width: '30%',
    height: '40px',
    border: 'solid 1.5px black',
    textAlign: 'right',
  },
  itemNameCell: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    width: '20%',
    background: 'rgb(240, 238, 238)',
    height: '40px',
    border: 'solid 1.5px black',
  },
  itemValueCell: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    width: '30%',
    height: '40px',
    border: 'solid 1.5px black',
  },
  addressValue: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    height: '40px',
    border: 'solid 1.5px black',
  },
  shoukenName: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    width: '50%',
    height: '40px',
    border: 'solid 1.5px black',
  },
  shoukenValue: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    width: '50%',
    height: '40px',
    border: 'solid 1.5px black',
  },
  restaurantDistance: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    width: '8%',
    height: '40px',
    border: 'solid 1.5px black',
  },
  no: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    width: '4%',
    height: '40px',
    border: 'solid 1.5px black',
    textAlign: 'center',
  },
  distanceShopName: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    width: '40%',
    height: '40px',
    border: 'solid 1.5px black',
  },
  distanceCategory: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    width: '24%',
    height: '40px',
    border: 'solid 1.5px black',
  },
  distanceSubCategory: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    width: '20%',
    height: '40px',
    border: 'solid 1.5px black',
  },
  marketArea: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    height: '40px',
    border: 'solid 1.5px black',
    width : 'calc(100% / 3)',
    textAlign: 'center',
  },
  subCategory: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    height: '40px',
    border: 'solid 1.5px black',
    width : 'calc(100% / 3)',
    textAlign: 'center',
  },
  subCategoryShopName: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    height: '40px',
    border: 'solid 1.5px black',
    width : 'calc(64% / 3)',
    textAlign: 'center',
  },
  facilityShopName: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    width: '46%',
    height: '40px',
    border: 'solid 1.5px black',
  },
  facilityCategory: {
    fontSize: '16pt',
    padding: '3px',
    color: 'black',
    width: '42%',
    height: '40px',
    border: 'solid 1.5px black',
  },
  attention: {
    fontSize: '16pt',
    position: 'relative',
    left: '10%',
    margin: 'auto',
    color: 'black',
  },
  space: {
    height: '40px',
  },
  date: {
    fontSize: '16pt',
    textAlign: 'right',
    position: 'relative',
    right: '10%',
    color: 'black',
  },
}));

export default function PredContent(props) {
  const classes = useStyles();
  const {
    id,
    predictRes,
    shopInput,
  } = props;

  const today = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    return yyyy + '/' + mm + '/' + dd;
  }

  const checkShopInputElement = (elem) => {
    if (shopInput[elem] !== '' && shopInput[elem] !== null) {
      return true;
    } else {
      return false;
    }
  }

  const predSales = () => {
    return (
      <div>
        <table className={classes.table}>
          <thead>
            <tr>
              <th colSpan={4} className={classes.inputGroupTitle}>売上予測</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={3} className={classes.itemNameCell}>予測金額</td>
              <td rowSpan={3} className={classes.result}>{predictRes.toLocaleString() + '円'}</td>
              <td className={classes.itemNameCell}>店内</td>
              <td className={classes.itemValueCell}>-</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>テイクアウト</td>
              <td className={classes.itemValueCell}>-</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>デリバリー</td>
              <td className={classes.itemValueCell}>-</td>
            </tr>
          </tbody>
        </table>
        <p className={classes.attention}>※：売上予測はオープン後4ヶ月目の数値となります。</p>
        <div className={classes.space}></div>
        <table className={classes.table}>
          <thead>
            <tr>
              <th colSpan={4} className={classes.inputGroupTitle}>基本属性</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.itemNameCell}>住所</td>
              <td colSpan={3} className={classes.addressValue}>{checkShopInputElement('住所') ? shopInput['住所'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>業態</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('業態') ? shopInput['業態'] : ''}</td>
              <td className={classes.itemNameCell}>座席</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('席数') ? shopInput['席数'] + '席' : ''}</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>大分類</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('大分類') ? shopInput['大分類'] : ''}</td>
              <td className={classes.itemNameCell}>営業時間</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('営業時間') ? shopInput['営業時間'] + '時間' : ''}</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>小分類</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('小分類') ? shopInput['小分類'] : ''}</td>
              <td className={classes.itemNameCell}>-</td>
              <td className={classes.itemValueCell}>-</td>
            </tr>
          </tbody>
        </table>
        <div className={classes.space}></div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td colSpan={4} className={classes.inputGroupTitle}>敷地/立地</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.itemNameCell}>敷地面積</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('敷地面積') ? shopInput['敷地面積'] + '坪' : ''}</td>
              <td className={classes.itemNameCell}>立地タイプ</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('立地タイプ') ? shopInput['立地タイプ'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>敷地内複合</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('敷地内複合') ? shopInput['敷地内複合'] : ''}</td>
              <td className={classes.itemNameCell}>交差点立地</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('交差点立地') ? shopInput['交差点立地'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>コンビニ隣接</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('コンビニ隣接') ? shopInput['コンビニ隣接'] : ''}</td>
              <td className={classes.itemNameCell}>切下げ有接道数</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('切下げ有接道数') ? shopInput['切下げ有接道数'] : ''}</td>
            </tr>
          </tbody>
        </table>
        <div className={classes.space}></div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td colSpan={4} className={classes.inputGroupTitle}>駐車場/最寄駅</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.itemNameCell}>台数</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('台数') ? shopInput['台数'] + '台' : ''}</td>
              <td className={classes.itemNameCell}>最寄駅</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('最寄駅') ? shopInput['最寄駅'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>充足度</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('充足度') ? shopInput['充足度'] + '%' : ''}</td>
              <td className={classes.itemNameCell}>乗降客数</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('乗降客数') ? shopInput['乗降客数'] + '人' : ''}</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>共有</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('共有') ? shopInput['共有'] : ''}</td>
              <td className={classes.itemNameCell}>距離</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('距離') ? shopInput['距離'] + 'm' : ''}</td>
            </tr>
          </tbody>
        </table>
        <div className={classes.space}></div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td colSpan={4} className={classes.inputGroupTitle}>周辺道路(メイン)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.itemNameCell}>間口</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('間口') ? shopInput['間口'] + 'm' : ''}</td>
              <td className={classes.itemNameCell}>トータル車線数</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('トータル車線数') ? shopInput['トータル車線数'] + '車線' : ''}</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>種類</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('メイン道路種類') ? shopInput['メイン道路種類'] : ''}</td>
              <td className={classes.itemNameCell}>店側車線数</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('店側車線数') ? shopInput['店側車線数'] + '車線' : ''}</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>分離帯有無</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('分離帯有無') ? shopInput['分離帯有無'] : ''}</td>
              <td className={classes.itemNameCell}>1車線当たり交通量</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('1車線当たり交通量') ? shopInput['1車線当たり交通量'] + '台' : ''}</td>
            </tr>
            <tr>
              <td className={classes.itemNameCell}>交通センサス合計</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('交通センサス合計') ? shopInput['交通センサス合計'] + '台': ''}</td>
              <td className={classes.itemNameCell}>-</td>
              <td className={classes.itemValueCell}>-</td>
            </tr>
          </tbody>
        </table>
        <div className={classes.space}></div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td colSpan={4} className={classes.inputGroupTitle}>周辺道路(側道)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.itemNameCell}>間口 ※車道のみ</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('間口 ※車道のみ') ? shopInput['間口 ※車道のみ'] + 'm' : ''}</td>
              <td className={classes.itemNameCell}>種類</td>
              <td className={classes.itemValueCell}>{checkShopInputElement('サブ1道路種類') ? shopInput['サブ1道路種類'] : ''}</td>
            </tr>
          </tbody>
        </table>
        <div className={classes.space}></div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td colSpan={2} className={classes.inputGroupTitle}>商圏</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.shoukenName}>男性人口15_徒歩5分</td>
              <td className={classes.shoukenValue}>{checkShopInputElement('男性人口15_徒歩5分') ? shopInput['男性人口15_徒歩5分'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.shoukenName}>女性人口15_徒歩5分</td>
              <td className={classes.shoukenValue}>{checkShopInputElement('女性人口15_徒歩5分') ? shopInput['女性人口15_徒歩5分'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.shoukenName}>人口総数15_徒歩5分</td>
              <td className={classes.shoukenValue}>{checkShopInputElement('人口総数15_徒歩5分') ? shopInput['人口総数15_徒歩5分'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.shoukenName}>人口総数増減15-10_徒歩5分</td>
              <td className={classes.shoukenValue}>{checkShopInputElement('人口総数増減15-10_徒歩5分') ? shopInput['人口総数増減15-10_徒歩5分'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.shoukenName}>昼間人口総数15S_徒歩5分</td>
              <td className={classes.shoukenValue}>{checkShopInputElement('昼間人口総数15S_徒歩5分') ? shopInput['昼間人口総数15S_徒歩5分'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.shoukenName}>世帯数15_徒歩5分</td>
              <td className={classes.shoukenValue}>{checkShopInputElement('世帯数15_徒歩5分') ? shopInput['世帯数15_徒歩5分'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.shoukenName}>一戸建主世帯数15_徒歩5分</td>
              <td className={classes.shoukenValue}>{checkShopInputElement('一戸建主世帯数15_徒歩5分') ? shopInput['一戸建主世帯数15_徒歩5分'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.shoukenName}>持ち家主世帯数15_徒歩5分</td>
              <td className={classes.shoukenValue}>{checkShopInputElement('持ち家主世帯数15_徒歩5分') ? shopInput['持ち家主世帯数15_徒歩5分'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.shoukenName}>共同住宅主世帯数15_徒歩5分</td>
              <td className={classes.shoukenValue}>{checkShopInputElement('共同住宅主世帯数15_徒歩5分') ? shopInput['共同住宅主世帯数15_徒歩5分'] : ''}</td>
            </tr>
            <tr>
              <td className={classes.shoukenName}>小売業計年間販売額(百万円)14S_徒歩5分</td>
              <td className={classes.shoukenValue}>{checkShopInputElement('小売業計年間販売額(百万円)14S_徒歩5分') ? shopInput['小売業計年間販売額(百万円)14S_徒歩5分'] : ''}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const nearShop = () => {
    return (
      <div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td colSpan={3} className={classes.inputGroupTitle}>周辺店舗(距離別件数)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.marketArea}>500m圏内</td>
              <td className={classes.marketArea}>1000m圏内</td>
              <td className={classes.marketArea}>1500m圏内</td>
            </tr>
            <tr>
              <td className={classes.marketArea}></td>
              <td className={classes.marketArea}></td>
              <td className={classes.marketArea}></td>
            </tr>
          </tbody>
        </table>
        <div className={classes.space}></div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td colSpan={5} className={classes.inputGroupTitle}>周辺飲食店(距離順)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.no}>No</td>
              <td className={classes.distanceShopName}>店名</td>
              <td className={classes.distanceCategory}>大分類</td>
              <td className={classes.distanceSubCategory}>小分類</td>
              <td className={classes.restaurantDistance}>距離</td>
            </tr>
            <tr>
              <td className={classes.no}>1</td>
              <td className={classes.distanceShopName}></td>
              <td className={classes.distanceCategory}></td>
              <td className={classes.distanceSubCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>2</td>
              <td className={classes.distanceShopName}></td>
              <td className={classes.distanceCategory}></td>
              <td className={classes.distanceSubCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>3</td>
              <td className={classes.distanceShopName}></td>
              <td className={classes.distanceCategory}></td>
              <td className={classes.distanceSubCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>4</td>
              <td className={classes.distanceShopName}></td>
              <td className={classes.distanceCategory}></td>
              <td className={classes.distanceSubCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>5</td>
              <td className={classes.distanceShopName}></td>
              <td className={classes.distanceCategory}></td>
              <td className={classes.distanceSubCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>6</td>
              <td className={classes.distanceShopName}></td>
              <td className={classes.distanceCategory}></td>
              <td className={classes.distanceSubCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>7</td>
              <td className={classes.distanceShopName}></td>
              <td className={classes.distanceCategory}></td>
              <td className={classes.distanceSubCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>8</td>
              <td className={classes.distanceShopName}></td>
              <td className={classes.distanceCategory}></td>
              <td className={classes.distanceSubCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>9</td>
              <td className={classes.distanceShopName}></td>
              <td className={classes.distanceCategory}></td>
              <td className={classes.distanceSubCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>10</td>
              <td className={classes.distanceShopName}></td>
              <td className={classes.distanceCategory}></td>
              <td className={classes.distanceSubCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
          </tbody>
        </table>
        <div className={classes.space}></div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td colSpan={10} className={classes.inputGroupTitle}>周辺飲食店(小分類別)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={3} className={classes.subCategory}>かつ丼・とんかつ(9件)</td>
              <td colSpan={3} className={classes.subCategory}>牛丼・牛かつ(9件)</td>
              <td colSpan={3} className={classes.subCategory}>うどん(9件)</td>
            </tr>
            <tr>
              <td className={classes.no}>No</td>
              <td className={classes.subCategoryShopName}>店名</td>
              <td className={classes.restaurantDistance}>距離</td>
              <td className={classes.no}>No</td>
              <td className={classes.subCategoryShopName}>店名</td>
              <td className={classes.restaurantDistance}>距離</td>
              <td className={classes.no}>No</td>
              <td className={classes.subCategoryShopName}>店名</td>
              <td className={classes.restaurantDistance}>距離</td>
            </tr>
            <tr>
              <td className={classes.no}>1</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>1</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>1</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>2</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>2</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>2</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>3</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>3</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>3</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td colSpan={3} className={classes.subCategory}>寿司(9件)</td>
              <td colSpan={3} className={classes.subCategory}>ラーメン・つけ麺(9件)</td>
              <td colSpan={3} className={classes.subCategory}>ファミレス(9件)</td>
            </tr>
            <tr>
              <td className={classes.no}>No</td>
              <td className={classes.subCategoryShopName}>店名</td>
              <td className={classes.restaurantDistance}>距離</td>
              <td className={classes.no}>No</td>
              <td className={classes.subCategoryShopName}>店名</td>
              <td className={classes.restaurantDistance}>距離</td>
              <td className={classes.no}>No</td>
              <td className={classes.subCategoryShopName}>店名</td>
              <td className={classes.restaurantDistance}>距離</td>
            </tr>
            <tr>
              <td className={classes.no}>1</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>1</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>1</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>2</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>2</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>2</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>3</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>3</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
              <td className={classes.no}>3</td>
              <td className={classes.subCategoryShopName}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
          </tbody>
        </table>
        <div className={classes.space}></div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td colSpan={3} className={classes.inputGroupTitle}>近隣集客施設(距離別件数)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.marketArea}>200m圏内</td>
              <td className={classes.marketArea}>400m圏内</td>
              <td className={classes.marketArea}>800m圏内</td>
            </tr>
            <tr>
              <td className={classes.marketArea}></td>
              <td className={classes.marketArea}></td>
              <td className={classes.marketArea}></td>
            </tr>
          </tbody>
        </table>
        <div className={classes.space}></div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td colSpan={10} className={classes.inputGroupTitle}>近隣集客施設(距離順)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.no}>No</td>
              <td className={classes.facilityShopName}>店名</td>
              <td className={classes.facilityCategory}>業態</td>
              <td className={classes.restaurantDistance}>距離</td>
            </tr>
            <tr>
              <td className={classes.no}>1</td>
              <td className={classes.facilityShopName}></td>
              <td className={classes.facilityCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>2</td>
              <td className={classes.facilityShopName}></td>
              <td className={classes.facilityCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>3</td>
              <td className={classes.facilityShopName}></td>
              <td className={classes.facilityCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>4</td>
              <td className={classes.facilityShopName}></td>
              <td className={classes.facilityCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>5</td>
              <td className={classes.facilityShopName}></td>
              <td className={classes.facilityCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>6</td>
              <td className={classes.facilityShopName}></td>
              <td className={classes.facilityCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>7</td>
              <td className={classes.facilityShopName}></td>
              <td className={classes.facilityCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>8</td>
              <td className={classes.facilityShopName}></td>
              <td className={classes.facilityCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>9</td>
              <td className={classes.facilityShopName}></td>
              <td className={classes.facilityCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
            <tr>
              <td className={classes.no}>10</td>
              <td className={classes.facilityShopName}></td>
              <td className={classes.facilityCategory}></td>
              <td className={classes.restaurantDistance}></td>
            </tr>
          </tbody>
        </table>
        <div className={classes.space}></div>
      </div>
    )
  }

  return (
    <div id={id} hidden>
      <div id='p1'>
      </div>
      <div id='p2'>
      </div>
    </div>
  );
}
