export async function getGeocode(google, shopInfo) {
  const geocoder = new google.maps.Geocoder();
  return new Promise((resolve) => {
    geocoder.geocode({address: shopInfo['address']}, (results, status) => {
      if (status === 'OK') {
        let location = {};
        location.lat = results[0].geometry.location.lat();
        location.lng = results[0].geometry.location.lng();
        resolve(location);
      } else {
        resolve();
      }
    });
  });
}