import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

export class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.classes = props.classes;
    this.google = props.google;
  }

  componentDidMount() {
    this.autocomplete = new this.google.maps.places.Autocomplete(
      this.autocompleteInput.current,
      { types: ['geocode'] }
    );

    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    const address = place.formatted_address;
    const addressComp = place.address_components;
    const provinceIndex =
      addressComp !== undefined && addressComp.length > 3
        ? addressComp[addressComp.length - 1].long_name.indexOf('-') > -1
          ? addressComp.length - 3
          : addressComp.length - 2
        : 0;
    let province =
      provinceIndex !== 0 ? addressComp[provinceIndex].long_name : '';
    if (address !== undefined) {
      const location = place.geometry.location;
      const pid = place.place_id;
      this.props.onPlaceLoaded(
        pid,
        address,
        place.name,
        {
          lat: location.lat(),
          lng: location.lng(),
        },
        province
      );
    }
    document.getElementById('autocomplete').value = place.name;
  }

  render() {
    return (
      <div className={this.classes.search}>
        <div className={this.classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          inputRef={this.autocompleteInput}
          id='autocomplete'
          placeholder='Search…'
          classes={{
            root: this.classes.inputRoot,
            input: this.classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
    );
  }
}

export default SearchBar;
