import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  progressRoot: {
    color: '#0fa0ce',
  },
}));

export default function CircularProgressWrapper() {
  const classes = useStyles();

  return (
    <div style={{ position: 'relative', display: 'inline', left: '35px', top: '30px'}}>
      <CircularProgress style={{height: '80px', width: '80px'}} variant="indeterminate" classes={{root: classes.progressRoot}} />
    </div>
  );
}
